import { graphql, useStaticQuery } from 'gatsby'
import { PaperModel } from '../models'

export const getGraphPapers = (): PaperModel[] => {
  const {
    allPapers: { nodes: papers },
  } = useStaticQuery(graphql`
    query {
      allPapers {
        nodes {
          authors {
            id
          }
          class
          institution
          isbn
          link
          name
          sourceBook
          summary
          type
          year
        }
      }
    }
  `)

  return papers.length > 0 ? papers : []
}
