import React from 'react'
import { navigate } from 'gatsby'

// GraphQL
import { getGraphAuthors } from '../../../graphQL/authors'

// Models
import { PaperModel } from '../../../models'

// Style
import {
  cardName,
  cardLink,
  cardAuthor,
  cardSummary,
  cardWrapper,
  cardContainer,
} from './style.module.scss'

interface Props {
  paper: PaperModel
}

const PaperCard: React.FC<Props> = ({
  paper: {
    id,
    name,
    authors,
    summary,
    institution,
    year,
    type,
    sourceBook,
    link,
  },
}) => {
  const allAuthors = getGraphAuthors()
  const authorsGraph = authors?.map((a) =>
    allAuthors.find((au) => au.id == a.id)
  )
  const names = authorsGraph
    ?.map((a) => a?.pseudonym || a?.fullname)
    .join(' - ')

  return (
    <div className={cardContainer} onClick={() => {}}>
      <a className={cardLink} href={link ?? '#'} target="_blank">
        <div className={cardWrapper}>
          <div className={cardName}>{name}</div>
          <div className={cardAuthor}>
            <b>
              {type}
              {sourceBook && ` en ${sourceBook}`}
            </b>
          </div>
          <div className={cardAuthor}>
            <b>Autor{authors && authors?.length > 1 && 'es'}:</b> {names}
          </div>
          <div className={cardAuthor}>
            <i>
              {institution} {'('}
              {year}
              {')'}
            </i>
          </div>
          <div className={cardSummary}>{summary}</div>
        </div>
      </a>
    </div>
  )
}

export default PaperCard
