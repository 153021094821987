import React from 'react'

// graphQL
import { getGraphPapers } from '../../../graphQL/papers'

// Components
import PaperCard from '../../atoms/PaperCard'

// Utils
import { normalizeText } from '../../../utils/utils'

// Style
import {
  comicsTitle,
  comicsWrapper,
  comicsDescription,
} from './style.module.scss'

const AllPapers: React.FC = () => {
  const allPapers = getGraphPapers()
    .sort((a, b) => (normalizeText(a.name) > normalizeText(b.name) ? 1 : -1))
    .sort((a, b) => (a.class < b.class ? 1 : -1))

  return (
    <>
      <h1 className={comicsTitle}>Artículos académicos</h1>
      <h3 className={comicsDescription}>
        Aquí puedes ver encontrar diferentes documentos cuyo tema de
        investigación se centró en el noveno arte nacional: Tesis de grado,
        publicaciones en libros, entre varios otros. Si conoces algún artículo
        académico que ya esté publicado y quieres que esté en esta lista,
        envíanos toda la información a nuestro correo:
      </h3>
      <div className={comicsWrapper}>
        {allPapers.map((paper) => (
          <PaperCard paper={paper} />
        ))}
      </div>
    </>
  )
}

export default AllPapers
